

.form_background {
    height: 100vh;
    width: 100vw;
    margin: 0 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
	background: rgb(34, 193, 195);
	background: linear-gradient(135deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%);
}



.form_container {
    display: flex;
    flex-direction: row;
    width: auto;
    height: auto;
    margin: auto auto;
    background: #ffffff;
    border-radius: 4px;
    font-size: 16px;
    box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, .12);
}

.form_container div {
    display: flex;
    text-align: center;
    flex-direction: row;
    margin: 30px 20px;
}

.form_container label{
    display: flex;
    align-items: center;
}

.form_container select {
    width: 40%;
    margin-left: 50px;
}



