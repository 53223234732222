.login_card {
    height: 100vh;
    width: 100vw;
    margin: 0 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
	background: rgb(34, 193, 195);
	background: linear-gradient(135deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%);
}

.login {
    display: flex;
    flex-direction: row;
    width: auto;
    height: auto;
    margin: auto auto;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, .12);
}

.login-button {
	appearance: none;
	-webkit-appearance: none;
	width: 100%; /* Esto hace que el botón ocupe todo el ancho de su contenedor */
	padding: 15px 20px; /* Este padding es más razonable */
	border-radius: 24px;
	background-color: #1100ff;
	color: #fff;
	font-size: 20px;
	font-weight: 500;
	box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, .13);
	border: none;
	transition: all .3s ease;
	outline: 0;
}


.login-button:hover {
	transform: translateY(-3px);
	box-shadow: 0 2px 6px -1px #69efa1;
	margin: 20;
}

.login-button:hover:active {
	transform: scale(.99);
}

.login-input {
	font-size: 16px;
	padding: 20px 0px ;
	height: 56px ;
	border: none ;
	width: 100%;
	border-bottom: solid 1px rgba(0, 0, 0, .1) ;
	background: #fff ;
	min-width: 280px ;
	box-sizing: border-box ;
	transition: all .3s linear ;
	color: #000 ;
	font-weight: 400 ;
	-webkit-appearance: none ;
	appearance: none ;
    border-radius: 5px;
}

.login-input:focus {
	border-bottom: solid 1px rgb(182, 157, 230) ;
	outline: 0 ;
	box-shadow: 0 2px 6px -8px rgba(rgb(182, 157, 230), .45) ;
    border-radius: 5px;
}

.floating-label {
	position: relative;
	margin-bottom: 10px;
	width: 100%;
	padding: 15px 0px;
	font-size: 13px;
}

.floating-label input:not(:placeholder-shown) {
	padding: 28px 0px 12px 0px;
}

.floating-label input:not(:placeholder-shown)+label {
	transform: translateY(-10px);
	opacity: .7;
}


.floating-label label {
	position: absolute;
	top: calc(50% - 20px);
	left: 0;
	opacity: 0;
	transition: all .3s ease;

}


h1 {
	font-size: 24px;
	font-weight: 600;
	color: #000;
	opacity: .85;
	margin-bottom: 50px !important;
}


@media (max-width: 600px) {
	.login_card {
	  width: 100%;
	  padding: 20px;
	}
	
	.login {
	  padding: 20px;
	}
  
	.login-input {
	  min-width: auto !important;
	}
  }
  
  @media (min-width: 600px) {
	.login_card {
	  padding: 30px;
	}
  
	.login {
	  padding: 30px;
	}
  
	.login-input {
	  min-width: 280px !important;
	}
  }
