
.parent_container {
    height: 100%;
    width: 100%;
    position: absolute;   

}

.problem_section {
    height: 20%;
}

.editor_section { 
    border-radius: 5px;
    border: #000000 5px solid;
    padding: 10px;
    overflow: hidden;
    flex-direction: column;
    height: 70%;
    position: relative;
}

/* .button_section {
    display: flex;
    flex-direction: row;
    height: 10%;
    padding: 10px;
    justify-content: space-between;
} */
/* 
.button_section Button {
    width: 20%;
} */



.code_container {
    display: flex;
    flex-direction: column;
    background: #e2e8f0;

}


.code_editor {

    border: 5px solid #000000;
}




